import React from 'react'

function Main({ children }) {
  return (
    <main style={{ width: '100%' }}>
      { children }
    </main>
  )
}

export default Main
