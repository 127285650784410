import React from 'react'

function ScaleIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" version="1.0" width="24" height="24" viewBox="0 0 1280.000000 1244.000000" preserveAspectRatio="xMidYMid meet">
      <metadata>
        Created by potrace 1.15, written by Peter Selinger 2001-2017
      </metadata>
      <g transform="translate(0.000000,1244.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
        <path d="M6320 12418 c-24 -13 -53 -36 -64 -51 -21 -28 -21 -41 -26 -784 l-5 -756 -107 -28 c-403 -105 -713 -420 -813 -824 l-22 -90 -1446 -5 -1446 -5 -28 -21 c-51 -38 -73 -81 -73 -144 0 -63 22 -106 73 -144 l28 -21 1446 -5 1446 -5 22 -90 c100 -404 410 -719 813 -824 l107 -28 0 -354 0 -354 -148 -3 -147 -3 2 -2967 3 -2967 488 -3 487 -2 0 2970 0 2970 -167 2 -168 3 0 354 0 354 107 28 c403 105 713 420 813 824 l22 90 1449 5 c1410 5 1451 6 1479 24 63 41 92 106 80 178 -7 40 -50 98 -89 121 -16 9 -361 13 -1470 17 l-1449 5 -22 90 c-100 404 -410 719 -813 824 l-107 28 -5 756 c-5 743 -5 756 -26 784 -26 36 -99 73 -144 73 -20 0 -56 -10 -80 -22z m-92 -2229 l-3 -304 -310 0 -310 0 3 25 c7 48 62 175 102 237 84 128 189 223 320 289 80 41 181 75 193 64 4 -3 6 -143 5 -311z m543 247 c136 -69 240 -166 325 -302 49 -79 109 -233 96 -246 -5 -5 -145 -7 -313 -6 l-304 3 -3 300 c-1 165 0 305 2 312 7 16 105 -15 197 -61z m-546 -1211 l0 -310 -25 3 c-47 6 -186 69 -251 112 -161 110 -279 268 -332 447 -9 28 -13 54 -9 57 3 4 143 5 311 4 l306 -3 0 -310z m967 285 c-14 -95 -115 -275 -210 -373 -92 -94 -288 -206 -382 -219 l-25 -3 -3 300 c-1 165 0 306 3 313 3 10 70 12 312 10 l308 -3 -3 -25z m-719 -1710 l27 -30 0 -1608 c0 -1786 6 -1638 -66 -1668 -29 -12 -39 -12 -68 0 -72 30 -66 -118 -66 1667 l0 1606 21 27 c43 54 105 57 152 6z"/>
        <path d="M2512 9050 c-16 -36 -489 -1062 -1052 -2280 -562 -1218 -1025 -2221 -1027 -2228 -4 -10 37 -12 197 -10 l203 3 849 1820 c467 1001 853 1824 857 1828 7 6 750 -1576 1580 -3360 l136 -293 202 0 202 0 -28 63 c-59 130 -2073 4493 -2081 4507 -6 11 -16 -2 -38 -50z"/>
        <path d="M9205 6838 c-576 -1250 -1052 -2281 -1057 -2290 -8 -17 4 -18 194 -18 l203 0 136 293 c829 1782 1573 3366 1580 3360 4 -4 390 -827 857 -1828 l849 -1820 203 -3 c160 -2 201 0 197 10 -15 39 -2100 4554 -2106 4561 -4 4 -479 -1016 -1056 -2265z"/>
        <path d="M125 4321 c-49 -22 -99 -77 -115 -126 -28 -86 18 -199 99 -242 l44 -23 2391 0 c2298 0 2393 1 2431 19 79 35 121 112 113 207 -6 72 -42 124 -109 158 l-53 26 -2380 0 c-2281 0 -2383 -1 -2421 -19z"/>
        <path d="M7821 4314 c-67 -34 -103 -86 -109 -158 -8 -95 34 -172 113 -207 38 -18 133 -19 2431 -19 l2391 0 44 23 c81 43 127 156 99 242 -16 49 -66 104 -115 126 -38 18 -140 19 -2421 19 l-2380 0 -53 -26z"/>
        <path d="M505 3748 c14 -64 90 -255 142 -357 325 -635 926 -1056 1635 -1147 174 -22 452 -15 618 15 430 77 802 268 1114 571 126 122 202 214 299 360 103 155 227 422 261 563 l6 27 -2041 0 -2041 0 7 -32z"/>
        <path d="M8226 3753 c29 -119 139 -370 221 -503 209 -338 506 -614 851 -788 301 -153 566 -220 902 -229 480 -14 911 117 1298 394 334 239 607 596 749 978 22 61 44 125 48 143 l7 32 -2041 0 -2041 0 6 -27z"/>
        <path d="M5584 1761 c-129 -21 -222 -153 -194 -277 20 -87 60 -137 140 -173 44 -20 62 -21 859 -21 610 0 824 3 855 12 59 18 138 99 155 161 38 133 -50 271 -188 297 -60 11 -1559 12 -1627 1z"/>
        <path d="M4601 1116 c-124 -45 -187 -200 -130 -321 26 -55 85 -108 138 -123 52 -16 3517 -17 3574 -1 51 14 115 73 140 129 51 116 -2 257 -118 311 -39 18 -106 19 -1805 18 -1360 0 -1773 -3 -1799 -13z"/>
        <path d="M3488 470 c-189 -32 -262 -263 -127 -399 78 -78 -206 -71 3055 -69 l2921 3 49 30 c91 57 137 180 104 280 -17 51 -76 114 -130 139 l-45 21 -2890 1 c-1589 1 -2911 -2 -2937 -6z"/>
      </g>
    </svg>
  )
}

export default ScaleIcon
