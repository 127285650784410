export const ROUTES = {
  MAIN: '/',
  ABOUT: '/about',
  SERVICES: '/services',
  DISMANTLING: '/dismantling',
  PRICE: '/price',
  REVIEWS: '/reviews',
  CONTACTS: '/contacts',
  VACANCY: '/vacancy',
}
