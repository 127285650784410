import React from "react";
import TableRow from "../../TableRow";
import aluET from "../../images/metals/alu-et.jpg";
import { Products } from "../../constants/products";

function BlackMetalTable() {

  const products = new Products()

  return (
    <>
      <TableRow bgGrey img={aluET} {...products.getPropsByKey('black metal')} />
      <TableRow img={aluET}  {...products.getPropsByKey('cast iron')} />
      <TableRow img={aluET}  {...products.getPropsByKey('car scrap')} />
    </>
  );
}

export default BlackMetalTable;
