import React from 'react';
import styles from '../../styles/sections/RoadmapSection.module.sass';
import { motion } from 'framer-motion';

function ActionCard({ title, icon, num }) {
  const variantsTop = {
    hidden: {
      opacity: 0,
      y: -100,
    },

    show: {
      opacity: 1,
      y: 0,
    },
  };

  const variantsBottom = {
    hidden: {
      opacity: 0,
      y: 100,
    },

    show: {
      opacity: 1,
      y: 0,
    },
  };

  const PositionBottom = () => (
    <motion.div className={styles.action}>
      <div className={styles.circle}>
        <motion.div
          variants={variantsTop}
          initial='hidden'
          whileInView='show'
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {num}
        </motion.div>
      </div>
      {/* <div className={styles.verticalLine} /> */}

      <motion.p
        variants={variantsBottom}
        initial='hidden'
        whileInView='show'
        transition={{ duration: 0.8, delay: 0.6 }}
        className={styles.actionText}
      >
        {title}
      </motion.p>
    </motion.div>
  );

  return PositionBottom();
}

export default ActionCard;
