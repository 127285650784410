import React from 'react'
import MainLayout from "../layouts/MainLayout"
import HomeSection from "../components/Sections/HomeSection"
import PageTitle from "../components/PageTitle"
import ContactUsMiniSection from "../components/Sections/ContactUsMiniSection";
import AboutDescription from "../components/Sections/AboutDescription";
import image1 from '../images/about/1.webp'
import image2 from '../images/about/2.webp'
import Paragraph from "../components/Paragraph"

function AboutPage() {
  return (
    <MainLayout>
      <HomeSection />
      <PageTitle title={'Прием лома черных и цветных металлов, сдать металлолом в Москве и Московской области'} />
      <AboutDescription
        img={image1}
        text={'ООО «Стандарт» – это пункт приема лома черного и цветного металла, который осуществляет вывоз по всей Москве и Московской области. У нас можно сдать металлолом в Москве и Московской области действительно дорого. Мы сотрудничаем напрямую с металл перерабатывающими заводами, а, значит, мы можем предложить очень выгодные для Вас цены на прием металлолома.'} />

      <Paragraph text={'Цветной металл — отрасль металлургии, которая включает добычу, обогащение руд цветных металлов и выплавку цветных металлов и их сплавов. По физическим свойствам и назначению цветные металлы условно можно разделить на тяжёлые (медь, свинец, цинк, олово, никель) и лёгкие (алюминий, титан, магний). На основании этого деления различают металлургию лёгких металлов и металлургию тяжёлых металлов.'} />
      <Paragraph text={'Металлолом — это различный металлический мусор. Различают черный лом, цветной лом и драгоценный лом. На протяжении всего своего существования мы уверенно развиваем свою деятельность в сфере сбора, хранения и переработки вторичного металлического сырья. За эти годы мы смогли заработать репутацию честной, открытой и успешной организации.'} />

      <AboutDescription
        reverse
        img={image2}
        text={'Также выполняем демонтаж конструкций, в том числе опасных и требующих осторожного обращения. Вывоз различных видов лома: кусковых, негабаритных, требующих применения спецтехники, лом меди, лом алюминия, черный лом. Наши сотрудники разбирают металлоконструкции любого уровня сложности. На первом месте у нас всегда соблюдение ваших интересов. Мы заботимся о том, чтобы экологичность и безопасность была выдержана в соответствии со всеми нормами.'} />

      <Paragraph text={'Мы возьмем на себя всю подготовку, демонтаж и транспортировку лома, для этого просто свяжитесь с нами по указанному контактному телефону или оформите заявку с помощью формы.'} />
      <Paragraph text={'Мы поможем Вам не только избавиться от ненужного, но и заработать деньги!'} />
      <ContactUsMiniSection />
    </MainLayout>
  )
}

export default AboutPage
