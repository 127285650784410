import React from 'react'
import aluET from "../../images/metals/alu-et.jpg"
import TableRow from "../../TableRow"
import { Products } from '../../constants/products'

function StainlessSteelTable() {

  const products = new Products()

  return (
    <>
      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey('Нержавеющая сталь габаритом 0,5х0,5х1,5 м, 10%')}
      />

      <TableRow
        img={aluET}
        {...products.getPropsByKey('Нержавеющая сталь негабаритная 10%')}
      />

      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey('Нержавеющая сталь 8% (в т. ч. негабаритная)')}
      />

      <TableRow
        img={aluET}
        {...products.getPropsByKey('Нержавеющая сталь 6%')}
      />
    </>
  )
}

export default StainlessSteelTable
