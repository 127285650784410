import React from 'react'
import TableRow from "../../TableRow"
import aluET from "../../images/metals/alu-et.jpg"

function CableTable() {
  return (
    <>
      <TableRow
        bgGrey
        img={aluET}
        title={''}
        requestPrice
      />
    </>
  )
}

export default CableTable
