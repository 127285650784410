import React from "react";
import aluET from "../../images/metals/alu-et.jpg";
import TableRow from "../../TableRow";
import { Products } from "../../constants/products";

function AluminumTable() {
  const products = new Products();

  return (
    <>
      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey("Алюминий 1-1 (электротехнический)")}
      />

      <TableRow img={aluET} {...products.getPropsByKey("Алюминий 1-1 (пищевой)")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("Алюминий АД-31 (Профиль) чистый")} />
      <TableRow img={aluET} {...products.getPropsByKey("Алюминий АД-31 (Профиль) грязный")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("Опалубка (засор по факту)")} />
      <TableRow img={aluET} {...products.getPropsByKey("Диски")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("Алюминиевая банка")} />
      <TableRow img={aluET} {...products.getPropsByKey("Алюминий (разносортный)")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("Алюминий моторка")} />
      <TableRow img={aluET} {...products.getPropsByKey("Алюминий офсет")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("Алюминий радиаторы")} />
      <TableRow img={aluET} {...products.getPropsByKey("Алюминиевая стружка (засор от 5%)")} />

      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey("Электродвигатель (корпус алюминий)")}
      />

      <TableRow img={aluET} {...products.getPropsByKey("Электродвигатель (корпус чугун)")} />
    </>
  );
}

export default AluminumTable;
