import React from 'react'

function CashStackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="#479e53" className="bi bi-cash-stack"
         viewBox="0 0 16 16">
      <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
      <path
        d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
    </svg>
  )
}

export default CashStackIcon
