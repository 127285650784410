import React from 'react'
import aluET from "../../images/metals/alu-et.jpg"
import TableRow from "../../TableRow"
import { Products } from '../../constants/products'

function BatteryTable() {


  const products = new Products()

  return (
    <>
      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey('Аккумуляторы (гель, полипропилен)')}
      />

      <TableRow
        img={aluET}
        {...products.getPropsByKey('Аккумуляторы (эбонит)')}
      />
    </>
  )
}

export default BatteryTable
