import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/ROUTES";
import AppContext from "../contexts/AppContext";
import styles from "../styles/FixPricePopup.module.sass";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import ArrowUpIcon from "./icons/ArrowUpIcon";
import { Products } from "../constants/products";

function FixPricePopup() {
  const { isFixedPopupOpen, setIsFixedPopupOpen } = useContext(AppContext);

  const { PRICE } = ROUTES;
  const navigate = useNavigate();

  const classes = {
    fixPricePopup: isFixedPopupOpen
      ? `${styles.fixPricePopup} ${styles.fixPricePopup_active}`
      : styles.fixPricePopup,
  };

  const onCloseClick = () => {
    setIsFixedPopupOpen(false);
  };

  const onPriceClick = () => {
    navigate(PRICE);

    setTimeout(() => {
      setIsFixedPopupOpen(false);
    }, 300);
  };

  const products = new Products();

  const fullTitleByKey = (key, ketPrice = "opt") => {
    const product = products.getByKey(key);

    if (product.price.requestPrice) return "по запросу";

    return `${product.price[ketPrice]} ${product.price.weight}`;
  };

  return (
    <div className={classes.fixPricePopup}>
      <div onClick={onCloseClick} className={styles.closeLine}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={styles.icon}
          fill="none"
          viewBox="0 0 24 24"
          stroke={"#ff0000"}
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      <div onClick={onCloseClick} className={styles.closeIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={styles.icon}
          fill="none"
          viewBox="0 0 24 24"
          stroke={"#ff0000"}
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>

      <h3 className={styles.title}>Ищите актуальные цены на металлолом?</h3>
      <div className={styles.phones}>
        <a className={styles.phone} href="tel:+7 (991) 777-78-95">
          +7 (991) 777-78-95
        </a>
        <a className={styles.phone} href="tel:+7 (800) 550-53-83">
          +7 (800) 550-53-83
        </a>
        <a className={styles.phone} href="tel:+7 (916) 091-74-09">
          +7 (916) 091-74-09
        </a>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Вид металла</th>
            <th>Цена (оптом)</th>
            <th>Цена (розница)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Алюминий (профиль чистый)</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {fullTitleByKey("Алюминий АД-31 (Профиль) чистый")}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {fullTitleByKey("Алюминий АД-31 (Профиль) чистый", "roz")}
            </th>
          </tr>
          <tr>
            <th>Алюминий (эл/тех)</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {fullTitleByKey("Алюминий 1-1 (электротехнический)")}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {fullTitleByKey("Алюминий 1-1 (электротехнический)", "roz")}
            </th>
          </tr>
          <tr>
            <th>Алюминий (диски)</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {fullTitleByKey("Диски")}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {fullTitleByKey("Диски", "roz")}
            </th>
          </tr>
          <tr>
            <th>Медь (блеск)</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {fullTitleByKey("Медь а1-1 (блеск)")}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {fullTitleByKey("Медь а1-1 (блеск)", "roz")}
            </th>
          </tr>
          <tr>
            <th>Медь (разносорт)</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {fullTitleByKey("Медь (разносорт.)")}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {fullTitleByKey("Медь (разносорт.)", "roz")}
            </th>
          </tr>
          <tr>
            <th>Латунь</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {fullTitleByKey("Латунь")}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {fullTitleByKey("Латунь", "roz")}
            </th>
          </tr>
          <tr>
            <th>Свинец чистый</th>
            <th>
              {/* опт */}
              <ArrowUpIcon />
              {fullTitleByKey("Свинец (оболочка кабеля) чистый")}
            </th>
            <th>
              {/* розница */}
              <ArrowUpIcon />
              {fullTitleByKey("Свинец (оболочка кабеля) чистый", "roz")}
            </th>
          </tr>

          <tr>
            <th>Аккумулятор</th>
            <th>
              {/* опт */}
              <ArrowUpIcon />
              {fullTitleByKey("Аккумуляторы (гель, полипропилен)")}
            </th>
            <th>
              {/* розница */}
              <ArrowUpIcon />
              {fullTitleByKey("Аккумуляторы (гель, полипропилен)", "roz")}
            </th>
          </tr>

          <tr>
            <th>Лом автомобиля</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {`${products.getByKey("Лом автомобиля").price.opt / 1000} руб/кг`}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {`${products.getByKey("Лом автомобиля").price.roz / 1000} руб/кг`}
            </th>
          </tr>
          <tr>
            <th>Чёрный металл</th>
            <th>
              {/* опт */}
              <ArrowDownIcon />
              {fullTitleByKey("Чёрный металл")}
            </th>
            <th>
              {/* розница */}
              <ArrowDownIcon />
              {fullTitleByKey("Чёрный металл", "roz")}
            </th>
          </tr>
        </tbody>
      </table>

      <button onClick={onPriceClick} className={styles.buttonPrice}>
        Прайс-лист
      </button>
    </div>
  );
}

export default FixPricePopup;
