import React from "react";
import aluET from "../../images/metals/alu-et.jpg";
import TableRow from "../../TableRow";
import { Products } from "../../constants/products";

function CuprumAndBrass() {
  const products = new Products();

  return (
    <>
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("A1-1 copper (gloss)")} />
      <TableRow img={aluET} {...products.getPropsByKey("copper (tire)")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("A1-2 copper (piece)")} />
      <TableRow img={aluET} {...products.getPropsByKey("copper (various grades)")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("brass")} />
      <TableRow img={aluET} {...products.getPropsByKey("brass shavings")} />
      <TableRow bgGrey img={aluET} {...products.getPropsByKey("radiators copper/brass")} />
      <TableRow img={aluET} {...products.getPropsByKey("bronze")}  />
    </>
  );
}

export default CuprumAndBrass;
