export const priceList = {
  black: {
    blackMetal: {
      opt: 25,
      roz: 24,
    },

    castIron: {
      opt: 25,
      roz: 24,
    },

    autoMet: {
      opt: 25,
      roz: 24,
    },
  },

  cuprum: {
    glitter: {
      opt: 690,
      roz: 680,
    },

    cable: {
      opt: 620,
      roz: 615,
    },

    piece: {
      opt: 685,
      roz: 675,
    },

    random: {
      opt: 680,
      roz: 670,
    },

    brass: {
      opt: 320,
      roz: 350,
    },

    brasShavings: {
      opt: 290,
      roz: 280,
    },

    radiators: {
      opt: 370,
      roz: 360,
    },

    bronze: {
      opt: 400,
      roz: 390,
    },
  },

  alum: {
    electrical: {
      opt: 140,
      roz: 120,
    },

    foodGrade: {
      opt: 125,
      roz: 115,
    },

    profileClean: {
      opt: 130,
      roz: 120,
    },

    profileDirt: {
      opt: 110,
      roz: 90,
    },

    garbage: {
      opt: 100,
      roz: 90,
    },

    disks: {
      opt: 110,
      roz: 100,
    },

    AluminumCan: {
      opt: 60,
      roz: 50,
    },

    random: {
      opt: 115,
      roz: 110,
    },

    motor: {
      opt: 95,
      roz: 80,
    },

    offset: {
      opt: 95,
      roz: 85,
    },

    radiators: {
      opt: 60,
      roz: 45,
    },

    shavings: {
      opt: 80,
      roz: 70,
    },

    electricEngineAlu: {
      opt: 45,
      roz: 40,
    },

    electricEngineCastIron: {
      opt: 35,
      roz: 30,
    },
  },

  stainless: {
    dimensional: {
      opt: 100,
      roz: 80,
    },

    oversizeTenPercent: {
      opt: 90,
      roz: 70,
    },

    oversizeEightPercent: {
      opt: 70,
      roz: 55,
    },

    oversizeSixPercent: {
      opt: 60,
      roz: 50,
    },
  },

  plumbum: {
    cableSheath: {
      opt: 120,
      roz: 110,
    },

    dirt: {
      opt: 110,
      roz: 100,
    },

    weights: {
      opt: 60,
      roz: 50,
    },

    grids: {
      opt: 80,
      roz: 50,
    },

    carburetor: {
      opt: 80,
      roz: 50,
    },
  },

  battery: {
    gel: {
      opt: 45,
      roz: 40,
    },

    ebonite: {
      opt: 35,
      roz: 30,
    },
  },
};
