import React from 'react'
import aluET from "../../images/metals/alu-et.jpg"
import TableRow from "../../TableRow"
import { Products } from '../../constants/products'

function PlumbumTable() {

  const products = new Products()

  return (
    <>
      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey('Свинец (оболочка кабеля) чистый')}
      />

      <TableRow
        img={aluET}
        {...products.getPropsByKey('Свинец (переплав) грязный')}
      />

      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey('Свинец (грузики)')}
      />

      <TableRow
        img={aluET}
        {...products.getPropsByKey('Цинк (карбюраторный, решётки)')}
      />

      <TableRow
        bgGrey
        img={aluET}
        {...products.getPropsByKey('Цинк (карбюратор в сборе)')}
      />
    </>
  )
}

export default PlumbumTable
