import React from 'react';
import AboutUsSection from './AboutUsSection';
import SectionTitle from './SectionTitle';
import styles from '../../styles/sections/AboutUsSection.module.sass';
import { motion } from 'framer-motion';
import truckImg from '../../images/truck.png';
import pointsImg from '../../images/demontaj.png';

function SectionWithImage() {
  return (
    <AboutUsSection>
      <SectionTitle title={'Принимаем лом с 2016 года'} divider />

      <div className={styles.wrapper}>
        <motion.p
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className={styles.text}
        >
          ООО Стандарт – оптовая металлозаготовительная и демонтажная организация, которая демонтирует,
          принимает и заготавливает металлолом с 2016г. За относительно короткое время нашей деятельности на
          рынке металлолома, благодаря честной и плодотворной работе наших специалистов и работников, а так же
          неравнодушным отношением к своим клиентам, нам удалось завоевать доверие многочисленных клиентов –
          от частных лиц до крупных заводов и холдингов, которые сдают нам металлолом на постоянной основе. Мы
          являемся крупной оптовой компанией, которая может предлагать лучшие условия на рынке заготовки
          металлолома, т.к. накопленный на наших пунктах приема металлолом напрямую поставляется на
          перерабатывающие металл заводы ежечасно.
        </motion.p>

        <motion.img
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className={styles.img}
          src={truckImg}
          alt='Truck'
        />
      </div>

      <SectionTitle title={'Цель компании'} divider />
      <div className={styles.wrapper}>
        <motion.img
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className={styles.img}
          src={pointsImg}
          alt='Points'
        />
        <motion.p
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className={styles.text}
        >
          Хотите быстро снести здание, разобрать любые конструкции или оборудование, а затем выгодно продать
          образовавшийся металл с вывозом? Вы обратились по адресу! Демонтаж и утилизация металла — это наша
          специализация. У нас достаточно техники и бригад рабочих, а, главное, достаточно опыта в этом
          ответственном вопросе. Готовы приступить к работе сегодня же.
        </motion.p>
      </div>
    </AboutUsSection>
  );
}

export default SectionWithImage;
