import React, { useState, useContext, useRef } from 'react';
import styles from '../styles/SpecialOfferPopup.module.sass';
import AppContext from '../contexts/AppContext';
import { send } from '@emailjs/browser';
import CloseIcon from './icons/CloseIcon';
import ErrorToast from './ErrorToast';

import InputMask from 'react-input-mask';

import emailjs from '@emailjs/browser';

function PhoneInput(props) {
  return (
    <InputMask
      mask='+7 (999) 999-99-99'
      name='phonenum'
      id='phone-mask'
      type='phone'
      placeholder='Номер телефона'
      value={props.value}
      onChange={props.onChange}
      style={{color: 'white'}}>
        
    </InputMask>
  );
}

function SpecialOfferPopup() {
  const { isSpecialOfferPopup, setIsSpecialOfferPopup, setIsToastOpen } = useContext(AppContext);
  const [values, setValues] = useState({
    name: '',
    phone: '',
  });
  const [msg, setMsg] = useState('');
  const [isErrorToastOpen, setIsErrorToastOpen] = useState(false);
  const classes = {
    section: isSpecialOfferPopup ? `${styles.section} ${styles.section_active}` : styles.section,
  };

  const onOverlayClick = () => setIsSpecialOfferPopup(false);
  const onCloseClick = () => setIsSpecialOfferPopup(false);

  const onChange = (evt) => {
    const { name, value } = evt.target;
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onSubmit = (evt) => {
    evt.preventDefault();
    if (values.name === '' || values.phone === '') {
      setMsg('Пожалуйста заполните все поля');
      setIsErrorToastOpen(true);
      setTimeout(() => {
        setIsErrorToastOpen(false);
      }, 1500);
    } else {
      send('service_h9d8ifl', 'template_hxwx5yl', values, 'user_B0RmS66rgUajGJ6kANxU7').then(
        function (response) {
          console.log('SUCCESS!', response.status, response.text);
          console.log('values', values)
          setValues({
            name: '',
            phone: '',
          });

          setIsSpecialOfferPopup(false);
          setIsToastOpen(true);

          setTimeout(() => {
            setIsToastOpen(false);
          }, 1500);
        },
        function (error) {
          console.log('FAILED...', error);
        }
      );
    }
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const phonenumel = form.current.phonenum.value

    if(form.current.username.value.length > 1 && phonenumel.length === 18 && !phonenumel.includes('_')){
      emailjs.sendForm('service_akwd9qi', 'template_c8yo8yg', form.current, '15Ye39pjIHbEXm3Nz')
      .then((result) => {
        setIsToastOpen(true)
        onOverlayClick()
        setTimeout(() => {
          setIsToastOpen(false)
        }, 1500)

      }, (error) => {
        console.log(error.text);
      });
    } else {
      setMsg("Пожалуйста заполните поля корректно")
      setIsErrorToastOpen(true)
      setTimeout(() => {
        setIsErrorToastOpen(false)
      }, 1500)
    }

  
  };

  const [phone, setPhone] = useState('');
  const handleInput = ({ target: { value } }) => setPhone(value);

  return (
    <section className={classes.section}>
      <div onClick={onOverlayClick} className={styles.overlay} />
      <div className={styles.wrapper}>
        <h3 className={styles.title}>
          Акция <span>+1000₽</span> за тонну при оформлении заявки (осталось 2 места)
        </h3>

        <form onSubmit={sendEmail} ref={form} className={styles.form}>
          <input style={{color: 'white'}} name='username' type='text' placeholder={'Имя'} />

          <PhoneInput
            value={phone}
            onChange={handleInput}>
          </PhoneInput>

          <button type="submit">Оставить заявку</button>
        </form>

        <div onClick={onCloseClick} className={styles.closeBtn}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={styles.icon}
            fill='none'
            viewBox='0 0 24 24'
            stroke={'#ff0000'}
            strokeWidth={2}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
          </svg>
        </div>
      </div>
      <ErrorToast msg={msg} isErrorToastOpen={isErrorToastOpen} />
    </section>
  );
}

export default SpecialOfferPopup;
